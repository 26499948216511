<!--
 * @Description: AKJERRT
 * @Date: 2022-09-14 18:14:09
 * @LastEditTime: 2022-09-23 10:16:03
 * @FilePath: \linkgap-front\src\views\aarget_budget\statistics\salesTargetSummary.vue
-->
<template>
  <a-spin :spinning="spinning">
    <a-card :border="false">
      <a-row type="flex" justify="start">
        <a-col :span="4">
          年份：
          <a-date-picker
            placeholder="请选择年份"
            format="YYYY"
            mode="year"
            :value="year"
            :open="open"
            @openChange="openChange"
            @panelChange="panelChange"
          />
        </a-col>
        <a-col class="article-deptId" :span="5">
          部门：
          <a-tree-select
            v-model="param.targetId"
            style="width: 250px"
            :dropdownStyle="{ maxHeight: '400px', maxWidth: '250px', overflow: 'auto' }"
            :treeData="depts"
            treeDefaultExpandAll
            placeholder="请选择部门"
            allowClear
          >
          </a-tree-select>
        </a-col>
        <a-col class="article-deptId" :span="9">
          <a-button @click="getData" type="primary" icon="search">查询</a-button>
          <a-button @click="reset" type="default" icon="sync">重置</a-button>
        </a-col>
      </a-row>
      <br />
      <a-table
        :columns="columns"
        :data-source="data"
        bordered
        size="middle"
        :scroll="{ x: 2500 }"
        :pagination="page"
        @change="changeTable"
      >
        <span slot="amount" slot-scope="text"> {{ text | formatMoney }} </span>
      </a-table>
    </a-card>
  </a-spin>
</template>

<script>
import moment from 'moment'
import { mixin } from '../components/basis'

const columns = [
  {
    title: '年份',
    dataIndex: 'targetDate',
    key: 'targetDate',
    width: 80,
    fixed: 'left',
    align: 'center',
  },
  {
    title: '部门',
    dataIndex: 'targetName',
    key: 'targetName',
    width: 150,
    fixed: 'left',
    align: 'center',
  },
  {
    title: '全年',
    dataIndex: 'targetAmount',
    key: 'targetAmount',
    width: 150,
    fixed: 'left',
    align: 'center',
    scopedSlots: { customRender: 'amount' },
    sorter: (a, b) => a.targetAmount - b.targetAmount,
  },
  {
    title: '第一季度',
    children: [
      {
        title: '1月',
        dataIndex: 'januaryAmount',
        key: 'januaryAmount',
        align: 'center',
        scopedSlots: { customRender: 'amount' },
      },
      {
        title: '2月',
        dataIndex: 'februaryAmount',
        key: 'februaryAmount',
        align: 'center',
        scopedSlots: { customRender: 'amount' },
      },
      {
        title: '3月',
        dataIndex: 'marchAmount',
        key: 'marchAmount',
        align: 'center',
        scopedSlots: { customRender: 'amount' },
      },
    ],
  },
  {
    title: '合计',
    dataIndex: 'qoneAmount',
    key: 'qoneAmount',
    align: 'center',
    scopedSlots: { customRender: 'amount' },
  },
  {
    title: '第二季度',
    children: [
      {
        title: '4月',
        dataIndex: 'aprilAmount',
        key: 'aprilAmount',
        align: 'center',
        scopedSlots: { customRender: 'amount' },
      },
      {
        title: '5月',
        dataIndex: 'mayAmount',
        key: 'mayAmount',
        align: 'center',
        scopedSlots: { customRender: 'amount' },
      },
      {
        title: '6月',
        dataIndex: 'juneAmount',
        key: 'juneAmount',
        align: 'center',
        scopedSlots: { customRender: 'amount' },
      },
    ],
  },
  {
    title: '合计',
    dataIndex: 'qtwoAmount',
    key: 'qtwoAmount',
    align: 'center',
    scopedSlots: { customRender: 'amount' },
  },
  {
    title: '第三季度',
    children: [
      {
        title: '7月',
        dataIndex: 'julyAmount',
        key: 'julyAmount',
        align: 'center',
        scopedSlots: { customRender: 'amount' },
      },
      {
        title: '8月',
        dataIndex: 'augustAmount',
        key: 'augustAmount',
        align: 'center',
        scopedSlots: { customRender: 'amount' },
      },
      {
        title: '9月',
        dataIndex: 'septemberAmount',
        key: 'septemberAmount',
        align: 'center',
        scopedSlots: { customRender: 'amount' },
      },
    ],
  },
  {
    title: '合计',
    dataIndex: 'qthreeAmount',
    key: 'qthreeAmount',
    align: 'center',
    scopedSlots: { customRender: 'amount' },
  },
  {
    title: '第四季度',
    children: [
      {
        title: '10月',
        dataIndex: 'octoberAmount',
        key: 'octoberAmount',
        align: 'center',
        scopedSlots: { customRender: 'amount' },
      },
      {
        title: '11月',
        dataIndex: 'novemberAmount',
        key: 'novemberAmount',
        align: 'center',
        scopedSlots: { customRender: 'amount' },
      },
      {
        title: '12月',
        dataIndex: 'decembermount',
        key: 'decembermount',
        align: 'center',
        scopedSlots: { customRender: 'amount' },
      },
    ],
  },
  {
    title: '合计',
    dataIndex: 'qfourAmount',
    key: 'qfourAmount',
    align: 'center',
    scopedSlots: { customRender: 'amount' },
  },
]

export default {
  mixins: [mixin],
  data() {
    return {
      data: [],
      columns,
      spinning: false,
      param: {
        targetId: '',
        targetDate: '',
      },
      year: moment(),
      open: false,
      page: {
        pageSize: 10,
        pageNumber: 1,
        total: 0,
        showSizeChanger: true,
        showTotal: () => `共${this.page.total}条`,
      },
    }
  },
  created() {
    this.getData()
  },
  methods: {
    /**
     * 部门-销售目标列表
     */
    getData() {
      this.spinning = true
      const params = {
        pageNumber: this.page.pageNumber,
        pageSize: this.page.pageSize,
        ...this.param,
      }
      this.axios.get('/api/dealer/salesTargets/sysDepartmentTargets/list', { params: params }).then((res) => {
        if (res.code == 200) {
          this.data = res.body.records
          this.page.total = res.body.total
        }
        this.spinning = false
      })
    },

    /**
     * 重置
     */
    reset() {
      this.year = moment()
      this.param = {}
      this.page.pageSize = 10
      this.page.pageNumber = 1
      this.getData()
    },

    /**
     * 更改分页
     */
    changeTable(pagination) {
      this.page = pagination
      this.page.pageNumber = pagination.current
      this.getData()
    },

    openChange(status) {
      if (status) {
        this.open = true
      } else {
        this.open = false
      }
    },
    panelChange(date) {
      this.year = date
      this.open = false
      this.param.targetDate = date.format('YYYY')
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../components/index.scss';
</style>